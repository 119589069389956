import React from 'react';
import { Helmet } from 'react-helmet';

const defaultDescription = "Visit ViharaTravels.com for all your travel needs. We offer the lowest airfares to SriLanka, and many other destinations in the world.";
const defaultKeywords = "vihara travels, viharatravels, viharatravels.com, vihara travels.com, travel to srilanka, upul travels, rio travels, low airfares, lowest airfares to srilanka, srilanka, srilanka travel, srilanka tourism, expedia, kayak, flights, air tickets";

function makeTitle(title) {
    return title ? `Vihara Travels | ${title}` : `Vihara Travels`;
}

export default function SEOHead({title, description, keywords}) {
    return (
        <Helmet>
            <title> {makeTitle(title)} </title>
            <meta name="description" content={description ? description : defaultDescription}/>
            <meta name="keywords" content={keywords ? keywords : defaultKeywords}/>
        </Helmet>
    )
}