import { Link } from 'gatsby';
import React from 'react';
import logo from "./images/logo.png";

export default class Navigation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mobileMenuActive: false
        }
    }

    toggleMobileMenu = () => {
        this.setState((state) => ({mobileMenuActive: !state.mobileMenuActive}));
    }

    render() {
        const { showLogo } = this.props;
        return (
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    {
                        showLogo ?
                        <a className="navbar-item" href="/">
                            <img src={logo} alt="Vihara Travels"/>
                        </a>
                        : null
                    }
                    <button className={`navbar-burger burger ${this.state.mobileMenuActive && "is-active"}`} aria-label="menu" aria-expanded="false" data-target="navbarLinks"
                    onClick={this.toggleMobileMenu}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </button>
                </div>
                <div id="navbarLinks" className={`navbar-menu ${this.state.mobileMenuActive && "is-active"}`}>
                    <div className="navbar-end">
                        <Link className="navbar-item" to="/" >
                            Home
                        </Link>
                        <Link className="navbar-item" to="/defensivedriving" >
                            Defensive Driving
                        </Link>
                        <a className="navbar-item" href="https://www.paypal.com/webapps/shoppingcart?flowlogging_id=af0f847ec9db5&mfid=1606757463481_af0f847ec9db5#/checkout/openButton" target="_blank">
                            Pay Fees
                        </a>
                        <a className="navbar-item" href="https://www.paypal.com/webapps/shoppingcart?flowlogging_id=9b7f846f64523&mfid=1606757763581_9b7f846f64523#/checkout/openButton" target="_blank">
                            Pay For Ticket
                        </a>
                    </div>
                </div>
            </nav>
        )
    }
}